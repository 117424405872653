import React from "react";
import SEO from "../components/Seo/seo"
import styled from 'styled-components';
import { responsive } from '../utils/responsive';
import Layout from "../components/Layout/Layout";
import { Link } from "gatsby";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { StyledMainContainer, StyledLeftContainer, StyledDescription, StyledButtonWrapper, StyledTitle, StyledLeftContentContainer } from './head';
import { StyledRightVerticalContainer, StyledVerticalImageWrapper } from './controllers';
import FooterComponent from "../components/Home/Footer/FooterComponent";


// ---- Images ----

import PowerImage from '../components/imagesStock/Power/PowerImage';


// ---- Power Styles ----

const StyledPowerImageWrapper = styled(StyledVerticalImageWrapper)`
    margin: 0 2vw;
`

const PowerSubpage = props => {

    return (
        <Layout>
            <SEO title="Power" />

            <StyledMainContainer>

                <StyledLeftContainer>

                    <StyledLeftContentContainer>

                        <StyledTitle>{props.uri.replace(/[/]/g, "")}</StyledTitle>

                        <StyledDescription>

                            <h1>Power Supply</h1>

                            <p>
                                The space under the lamp is designed to house an efficient Mean Well transformer, which is included in the set.
                            </p>

                        </StyledDescription>

                        <StyledButtonWrapper>
                            <Link title="This is a link to base subpage" as="a" to="/base" aria-label="base"><h1>Back</h1></Link>
                            <Link title="This is a link to home page" as="a" to="/" aria-label="home"><h1>Home</h1></Link>
                        </StyledButtonWrapper>

                    </StyledLeftContentContainer>

                </StyledLeftContainer>

                <StyledRightVerticalContainer>

                    <Carousel
                        swipeable={true}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        ssr={true}
                        keyBoardControl={true}
                        removeArrowOnDeviceType={["tabletPro", "mobile"]}
                    >

                        <StyledPowerImageWrapper>
                            <PowerImage />
                        </StyledPowerImageWrapper>

                    </Carousel>

                    <FooterComponent />

                </StyledRightVerticalContainer>

            </StyledMainContainer>

        </Layout>
    )
}

export default PowerSubpage;